var validators = {
    dateRange: function(value, element, param) {
        var date_value = new Date(value);
        return this.optional( element ) || ( date_value >= new Date(param[0]) && date_value <= new Date(param[1]));
    },
    dateMin: function(value, element, param) {
        return this.optional( element ) || new Date(value) >= new Date(param);
    },
    dateMax: function(value, element, param) {
        return this.optional( element ) || new Date(value) <= new Date(param);
    },
    notBeforeThan: function(value, element, param) {
        // Bind to the blur event of the target in order to revalidate whenever the target field is updated
        var target = $( param );
        if ( this.settings.onfocusout && target.not( ".validate-equalTo-blur" ).length ) {
            target.addClass( "validate-equalTo-blur" ).on( "blur.validate-equalTo", function() {
                $( element ).valid();
            } );
        }
        return this.optional( element ) || this.optional( target.get(0) ) || new Date(value) >= new Date(target.val());
    },
};

$(function () {
    if ($.validator != undefined) {
        for (validator in validators) {
            $.validator.addMethod(validator, validators[validator]);
        };
    };
});
