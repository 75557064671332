$(function () {
    "use strict";

    var cookieAlert = document.querySelector(".cookiealert");
    var acceptCookies = document.querySelector(".acceptcookies");

    if (!cookieAlert) {
       return;
    }

    cookieAlert.offsetHeight;

    if (!Cookies.get("DISMISS_COOKIES_ALERT")) {
        cookieAlert.classList.add("show");
    }

    acceptCookies.addEventListener("click", function () {
        Cookies.set("DISMISS_COOKIES_ALERT", true, { expires: 365 })
        cookieAlert.classList.remove("show");
    });

});
