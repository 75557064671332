$(function () {
    $("a[data-locale]").each(function () {
        $(this).on("click", function () {
            locale = this.getAttribute("data-locale");
            Cookies.set("LANG", locale, { expires: 365 });
            location.reload();
            return false;
        });
    });
});
